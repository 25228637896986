@import url('https://fonts.googleapis.com/css2?family=Raleway&family=JetBrains+Mono&display=swap');

$screen-sm: 479px;
$screen-md: 767px;
$screen-lg: 1000px;
$screen-xl: 1278px;

:root {
  --primary: #e1983c;
  --primary-content: #1a1004;
  --primary-dark: #cb7f1f;
  --primary-light: #e8af68;

  --secondary: #3c85e1;
  --secondary-content: #ffffff;
  --secondary-dark: #1f6bcb;
  --secondary-light: #68a1e8;

  --background: #1d1a16;
  --foreground: #2c2721;
  --border: #494136;

  --text: #fcfbfb;
  --text-light: #ded9d3;
  --text-lighter: #b3a798;

  --success: #3ce13c;
  --warning: #e1e13c;
  --error: #e13c3c;
  --success-content: #041a04;
  --warning-content: #1a1a04;
  --error-content: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

body {
  overflow: hidden;
  user-select: none;

  p {
    color: var(--text-light);
  }
}


#Root,
#App {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: var(--background);
}

.title {
  color: var(--primary);
  font-size: clamp(4rem, 10vw, 7.5rem);
  min-height: 1.2em;
  margin: auto;
  padding: 0 12vw;
  text-align: center;
}

.socials {
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  transform: translateY(50%);
  right: clamp(25px, 4vw, 100px);
  bottom: 50%;
  height: fit-content;
  border: 3px solid var(--border);
  background-color: var(--foreground);
  border-radius: 20px;


  a {
    color: var(--primary-light);
    padding: 5px 10px;

    &:hover {
      color: var(--primary-dark);
      transform: rotate(2deg) scale(1.05);
    }
  }
}

.tux {
  opacity: 0;

  &:hover {
    opacity: 100;
  }

  img {
    position: absolute;
    bottom: 10px;
    left: 10px;
    height: 100px;
  }
}

@media (max-width: $screen-lg) {
  .title {
    padding-left: 10px;
    padding-right: 45px;
    padding-bottom: calc(clamp(20px, 10vh, 150px) + 80px);

    #Cursor {
      position: absolute;
    }
  }

  .socials {
    flex-direction: row;
    transform: translateX(50%);
    right: 50%;
    bottom: clamp(20px, 10vh, 150px);

    a {
      padding: 10px 5px;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }

  .tux,
  .noScroll {
    display: none !important;
  }
}

// animations
.fade {
  transition: 0.25s ease-in-out;
}