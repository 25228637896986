#Cursor {
    animation: 1s ease infinite blink;
}

.hidden {
    display: none;
}


@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}