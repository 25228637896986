.noScroll {
    opacity: 0;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 0;

    img {
        height: 100px;
    }

    p {
        font-family: 'JetBrains Mono';
        text-align: center;
        height: 25px;
        margin-top: 20px;
    }
}